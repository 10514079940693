<template lang="pug">
div
  .title(v-if="!isAllReIntegrated")
    om-heading(h3) {{ $t('reIntegration.deprecatedIntegrations') }}
  .campaign-error-wrapper
    .alert-block
      re-integration-error(v-if="hasError")
  .integrations
    .deprecated.d-flex(
      v-for="{ _id, data: { name } } in filteredIntegrations"
      v-if="(campaignsByStatus[_id] && campaignsByStatus[_id].sum > 0) || isReintegrated(_id)"
    )
      .name.d-flex.align-items-center.text-overflow {{ name }}
      .d-flex.align-items-center(v-if="!isReintegrated(_id)")
        .marker {{ $t('reIntegration.deprecatedText', getMarkers(_id)) }}
      .reconnect
        template(v-if="isReintegrated(_id)")
          om-chip(block color="secondary" icon="CheckCircle") {{ $t('reIntegration.reconnected') }}
        template(v-else)
          om-button(block primary :loading="loadingState.includes(_id)" @click="reconnect(_id)") {{ $t('reIntegration.reconnect') }}
</template>
<script>
  import { getReIntegrationRedirectUrl } from '@/utils/oauth';
  import reIntegrationMixin from './reIntegration';

  export default {
    components: {
      ReIntegrationError: () => import('@/components/ReIntegration/Alerts/Error.vue'),
    },
    mixins: [reIntegrationMixin],
    props: {
      integrationType: {
        type: String,
        required: true,
      },
      filteredIntegrations: {
        type: Array,
        required: true,
      },
      campaignsByStatus: {
        required: true,
      },
    },
    data() {
      return {
        loadingState: [],
      };
    },
    computed: {
      hasError() {
        return !!this.$route.query?.reIntegrationError;
      },
    },
    methods: {
      getBaseIntegrationData(integrationId) {
        return {
          type: 'hubSpotV2',
          reIntegration: true,
          data: {
            integrationId,
          },
        };
      },
      getMarkers(integrationId) {
        return {
          all: this.campaignsByStatus[integrationId].sum,
          active: this.campaignsByStatus[integrationId].activeCnt,
        };
      },
      reconnect(integrationId) {
        this.loadingState.push(integrationId);
        window.location.href = getReIntegrationRedirectUrl(
          this.getBaseIntegrationData(integrationId),
        );
      },
    },
  };
</script>
<style lang="sass">
  .reconnect
    .btn
      min-width: 130px
    .chip
      min-width: 130px
      min-height: 40px
      margin-right: unset
  .campaign-error-wrapper
    width: 620px
    padding: 1.5rem 0 1rem 1rem
</style>

import { apiBaseUrl } from '@/config/url';
import { generateRandomStr } from '@/util';

const _i18n = () => window.parent.om.i18n;

const getDecodedData = (integration, campaignId) => {
  if (integration.type === 'mailUp') {
    return window.btoa(
      `${integration.data.name}|${campaignId}|${integration.data.client_id}|${integration.data.client_secret}`,
    );
  }

  return window.btoa(`${integration.data.name}|${campaignId}`);
};

const getReIntegrationRedirectUrl = (integration) => {
  const {
    type,
    data: { integrationId },
  } = integration;

  if (type === 'hubSpotV2') {
    return `${apiBaseUrl}/integration-oauth/hubSpotV2/auth?state=reIntegration&integrationId=${integrationId}`;
  }
};

const getOauthRedirectUrl = (integration, campaignId) => {
  const hasFields = Object.keys(integration.data).length > 1;
  const decodedData = getDecodedData(integration, campaignId);

  let redirectUrl = `${apiBaseUrl}/integration-oauth/${integration.type}`;
  let params = {};

  if (integration.type === 'copernica') {
    redirectUrl += `?data=${decodedData}`;
    params.randomStr = generateRandomStr(32);
  } else if (integration.type === 'salesforce') {
    params.state = decodedData;
  } else if (integration.type === 'smsBump') {
    redirectUrl += `/start?state=${decodedData}&shopifyDomain=${integration.data.shopifyDomain}`;
    delete integration.data.shopifyDomain;
  } else if (integration.type === 'keap') {
    return `${apiBaseUrl}/integration-oauth/keap/auth?state=${decodedData}`;
  } else if (integration.type === 'hubSpotV2') {
    return `${apiBaseUrl}/integration-oauth/hubSpotV2/auth?state=${decodedData}`;
  } else if (integration.type === 'highLevel') {
    return `${apiBaseUrl}/integration-oauth/highLevel/auth?state=${decodedData}`;
  } else {
    redirectUrl += `?state=${decodedData}`;
  }

  redirectUrl = encodeURI(redirectUrl);

  params = hasFields ? { ...params, ...integration.data, redirectUrl } : { ...params, redirectUrl };

  return _i18n().t(`integrations.${integration.type}.link`, params);
};

export { getOauthRedirectUrl, getReIntegrationRedirectUrl };
